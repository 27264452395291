




































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import { StudentResponseType } from '@/models/api/students'

@Component({
  components: {
    ModalBase,
    ButtonBase,
    SelectBase,
    InputWithLabel,
  },
})
export default class ModalStudentNameEdit extends Mixins(ModalBaseMethod) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private id: number | null = null
  private isSent = false

  private nickname = ''
  private studentCode = ''

  @Prop()
  schoolOptionDatas!: { text: string; value: number }[]
  private schoolSelectedData: number | null = null

  @Prop()
  private gradeOptionDatas!: { text: string; value: number }[]
  private gradeSelectedData: number | null = null

  private get nameErrors(): string[] {
    const errors: string[] = []
    if (!this.nickname) {
      return errors
    }
    if (this.nickname.length > 20) {
      errors.push('氏名は20文字までです')
    }
    return errors
  }

  private get codeErrors(): string[] {
    const errors: string[] = []
    if (!this.studentCode) {
      return errors
    }
    if (this.studentCode.length > 20) {
      errors.push('生徒IDは20文字までです')
    }
    if (!this.studentCode.match(/^[0-9a-zA-Z]*$/)) {
      errors.push('生徒IDは半角英数字で入力してください')
    }
    return errors
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const permitted =
      this.nickname &&
      this.nameErrors.length === 0 &&
      this.studentCode &&
      this.codeErrors.length === 0 &&
      this.schoolSelectedData &&
      this.gradeSelectedData
    return permitted ? 'blue' : 'pointer-events-none'
  }

  /**
   * タイトル制御
   */
  private get title(): string {
    return this.id ? '生徒編集' : '生徒新規追加'
  }

  public async showModal(student: StudentResponseType | null) {
    this.isSent = false
    if (student) {
      this.id = student.studentId
      this.nickname = student.nickname
      this.studentCode = student.studentCode
      this.schoolSelectedData = student.schoolId
      this.gradeSelectedData = student.gradeId
    } else {
      this.id = null
      this.nickname = ''
      this.studentCode = ''
      this.schoolSelectedData = null
      this.gradeSelectedData = null
    }
    this.show()
  }

  private async saveStudent() {
    if (this.isSent) return

    this.isSent = true
    if (this.id) {
      this.updateStudent()
    } else {
      this.createStudent()
    }
  }

  private async updateStudent() {
    Vue.prototype.$http.httpWithToken
      .patch(`/students/${this.id}`, this.sendParams())
      .then(() => {
        alert('更新しました。')
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('生徒コードが既に存在するため更新できません。')
        } else {
          alert('更新に失敗しました。')
        }
      })
      .finally(() => {
        this.$emit('save-finished')
        this.hide()
      })
  }

  private async createStudent() {
    const isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
    const endPoint = isV3 ? '/v3/students' : '/students'
    Vue.prototype.$http.httpWithToken
      .post(endPoint, this.sendParams())
      .then(() => {
        alert('登録しました。')
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('生徒コードが既に存在するため登録できません。')
        } else {
          alert('登録に失敗しました。')
        }
      })
      .finally(() => {
        this.$emit('save-finished')
        this.hide()
      })
  }
  private sendParams() {
    return {
      branchId: this.branchId,
      nickname: this.nickname,
      code: this.studentCode,
      schoolId: this.schoolSelectedData,
      gradeId: this.gradeSelectedData,
    }
  }
}
