









import { Component, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import TableBase from '@/components/atoms/TableBase.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import WeekCodeMethods from '../atoms/WeekCodeMethods.vue'

@Component({
  components: {
    ButtonBase,
    SelectBase,
    TableBase,
    ModalBase,
  },
})
export default class ModalStudentStatus extends Mixins(ModalBaseMethod, WeekCodeMethods) {
  private id = 0

  private studentData = {
    name: '',
    gdlsCode: '',
  }

  private studentCurriculums: {
    curriculumType: string
    dayOfWeek: string
    name: string
  }[] = []

  private tableFields = [
    { key: 'curriculumType', label: '指導タイプ' },
    { key: 'dayOfWeek', label: '曜日' },
    { key: 'name', label: '授業名' },
  ]

  public async showModal(id: number) {
    Vue.prototype.$http.httpWithToken
      .get(`/student_timetables`, { params: { userId: id } })
      .then((res: any) => {
        const data = res.data
        this.studentData.name = data.nickname
        this.studentData.gdlsCode = data.gdlsCode
        this.studentCurriculums = res.data.timeTables.map(
          (timetable: { isGroup: boolean; weekCodes: string[]; className: string }) => {
            return {
              curriculumType: timetable.isGroup ? '集団' : '個別',
              dayOfWeek: this.weekCodesToTexts(timetable.weekCodes).join(''),
              name: timetable.className,
            }
          }
        )
      })
      .then(() => {
        this.show()
      })
      .catch(() => {
        alert('生徒情報の取得に失敗しました。更新して再度お試しください。')
      })
  }
}
