import { Component, Vue } from 'vue-property-decorator'

@Component
export default class GlinkBranchControlable extends Vue {
  // 学研連携対象フラグ
  isGlinkBranch = false

  /**
   * 学研連携対象の教室であるかの判定APIをリクエストする
   * @param branchId 教室ID
   */
  protected async isGlinkBranchApi(branchId: number) {
    const params = { branchId: branchId }

    const res = await Vue.prototype.$http.httpWithToken.get('/branches/isGlinkBranch', {
      params: params,
    })
    this.isGlinkBranch = res?.data?.isGlinkBranch || false
  }
}
