









import { Component, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import TableBase from '@/components/atoms/TableBase.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    ButtonBase,
    SelectBase,
    TableBase,
    ModalBase,
  },
})
export default class ModalStudentEndCondition extends Mixins(ModalBaseMethod) {
  private id = 0

  private studentData = {
    name: '',
    gdlsCode: '',
  }

  private studentEndConditions: {
    subjectName: string
    enCondition: string
    syCondition: string
  }[] = []

  private tableFields = [
    { key: 'subjectName', label: '教科' },
    { key: 'enCondition', label: '演習終了条件' },
    { key: 'syCondition', label: '宿題終了条件' },
  ]

  public async showModal(id: number) {
    Vue.prototype.$http.httpWithToken
      .get(`/studentEndConditions`, { params: { userId: id } })
      .then((res: any) => {
        const data = res.data
        this.studentData.name = data.nickname
        this.studentData.gdlsCode = data.gdlsCode
        this.studentEndConditions = res.data.endConditions.map(
          (endCondition: { subjectName: string; enCondition: string; syCondition: string }) => {
            return {
              subjectName: endCondition.subjectName,
              enCondition: endCondition.enCondition ? endCondition.enCondition : '-',
              syCondition: endCondition.syCondition ? endCondition.syCondition : '-',
            }
          }
        )
      })
      .then(() => {
        this.show()
      })
      .catch(() => {
        alert('終了条件の取得に失敗しました。更新して再度お試しください。')
      })
  }
}
